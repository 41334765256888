const theme = {
  colors : {
    primary: '#ffffff',
    secondary: '#00b0f0',
    tertiary: '#e8e8e8',
    secondaryDark: '#009ed8',
    red: "#ff0000",
  }
}

export default theme;