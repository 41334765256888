import React, { useEffect, useState } from "react";
// Tools
import theme from "utils/theme";
import styled from 'styled-components';
import { navigate } from "gatsby";
import Icon from "utils/icon";
// Images
import Sevenmed from "images/sevenmed.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1230px;
`

const NavBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.tertiary};
  height: 60px;
  width: 90%;
  max-width: 1230px;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 100px;
    padding: 0 20px;
  }
`;

const Separator = styled.div`
  height: 50%;
  width: 1px;
  background-color: ${theme.colors.primary};

  @media (max-width: 768px) {
    height: 1px;
    width: 20%;
  }
`;

const Element = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  height: 100%;
  width: 100%;
  font-size: 1.2rem;

  &:hover {
    color: ${theme.colors.secondary};
  }

  @media (max-width: 768px) {
    height: 100px;
    font-size: 1.5rem;
  }
`

const Image = styled.img`
  width: 100px;
`;

const StyledIcon = styled(Icon)`
  margin: 0 20px;
`;

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.tertiary};
  height: 100vh;
  position: absolute;
  top: 0;
  width: ${({ sideBarOpen }) => sideBarOpen ? '100vw' : '0'};
  right: ${({ sideBarOpen }) => sideBarOpen ? '0' : '-100vw'};
  z-index: 100;
  overflow: hidden;
  transition: 0.2s;
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const NavBar = () => {

  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [tabletBreakpoint, setTabletBreakpoint] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setTabletBreakpoint(window.matchMedia("(max-width: 768px)")?.matches);
    };
    setTabletBreakpoint(window.matchMedia("(max-width: 768px)")?.matches);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleNavigation = (path) => {
    setSideBarOpen(false);
    navigate(path);
  }

  return (
    <Container>
      { tabletBreakpoint &&
        <SideBar sideBarOpen={sideBarOpen}>
          <CloseIcon icon="Close" onClick={() => setSideBarOpen(false)} size="3x" />
          <Element onClick={() => handleNavigation("/")}>Accueil</Element>
          <Separator />
          <Element onClick={() => handleNavigation("/product/list")}>Produits</Element>
          <Separator />
          <Element onClick={() => handleNavigation("/about")}>A propos de nous</Element>
          <Separator />
          <Element onClick={() => handleNavigation("/contact")}>Nous contacter</Element>
        </SideBar>
      }
    { tabletBreakpoint ? 
        <NavBarContainer>
          <Bar>
            <Image src={Sevenmed} onClick={() => navigate('/')}/>
            <StyledIcon icon="Menu" size="2x" onClick={() => setSideBarOpen(true)}/>
          </Bar>
        </NavBarContainer>
      :
        <>
          <LogoContainer>
            <Image src={Sevenmed} onClick={() => navigate('/')}/>
          </LogoContainer>
          <NavBarContainer>
            <Bar>
              <Element onClick={() => navigate("/")}>Accueil</Element>
              <Separator />
              <Element onClick={() => navigate("/product/list")}>Produits</Element>
              <Separator />
              <Element onClick={() => navigate("/about")}>A propos de nous</Element>
              <Separator />
              <Element onClick={() => navigate("/contact")}>Nous contacter</Element>
            </Bar>
          </NavBarContainer>
        </>
    }
    </Container>
  )
}

export default NavBar;