import { navigate } from 'gatsby';
import React from 'react';
// Tools
import styled from 'styled-components';
import theme from 'utils/theme';

const FooterWrapper = styled.div`
  background-color: ${theme.colors.tertiary};
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-around;
  width: 90%;
`;  

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 20%;
`;

const LinkListItem = styled.div`
  margin: 5px 0;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const Title = styled.h3`
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const LegalDiv = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-top: 10px;
`;

const LegalText = styled.p`
  margin: 0 5px;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <ColumnWrapper>
        <Column>
          <Title>Sevenmed</Title>
          <LinkListItem>SEVENMED est le distributeur officiel et exclusif français de de la société METRUM CRYOFLEX, spécialisée dans la CRYOTHERAPIE et les système de CRYONEUROLYSE.</LinkListItem>
        </Column>
        <Column>
          <Title>Navigation</Title>
          <LinkListItem onClick={() => navigate("/")} > Accueil </LinkListItem>
          <LinkListItem onClick={() => navigate("/product/list")} > Produits </LinkListItem>
          <LinkListItem onClick={() => navigate("/about")} > A propos de nous </LinkListItem>
          <LinkListItem onClick={() => navigate("/contact")} > Nous contactez </LinkListItem>
        </Column>
        <Column>
          <Title>Contact</Title>
          <LinkListItem> Email </LinkListItem>
          <LinkListItem> Téléphone </LinkListItem>
        </Column>
      </ColumnWrapper>
      <LegalDiv>
        <LegalText>© 2023 Sevenmed</LegalText>
        <LegalText>-</LegalText>
        <LegalText onClick={() => navigate("/mention")} style={{cursor: "pointer"}}>Mentions Légales</LegalText>
      </LegalDiv>
    </FooterWrapper>
  );
};

export default Footer;