exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mention-js": () => import("./../../../src/pages/mention.js" /* webpackChunkName: "component---src-pages-mention-js" */),
  "component---src-pages-product-[id]-js": () => import("./../../../src/pages/product/[id].js" /* webpackChunkName: "component---src-pages-product-[id]-js" */),
  "component---src-pages-product-list-index-js": () => import("./../../../src/pages/product/list/index.js" /* webpackChunkName: "component---src-pages-product-list-index-js" */)
}

