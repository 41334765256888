import React from "react";


// Global styles
import { createGlobalStyle } from 'styled-components';

// Tools
import theme from "./src/utils/theme";

// Components
import NavBar from "./src/components/navBar";
import Footer from "./src/components/footer";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: ${theme.colors.primary};
    
    @font-face {
      font-family: 'Geologica';
      src: url('./src/font/Geologica.ttf') format("truetype-variations");
    }

    font-family: 'Geologica', sans-serif;
  }
`;

const withProvider = ({ element }) => {

  return (
    <>
      <GlobalStyle />
      <NavBar />
      {element}
      <Footer />
    </>
  );
};

export default withProvider;