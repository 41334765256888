// 1 - import svgs
import Menu from "images/icons/menu.svg";
import Close from "images/icons/close.svg";
import Search from "images/icons/search.svg";
import Down from "images/icons/down.svg";
// 2 - SVGList
const iconMap = {
  Menu,
  Close,
  Search,
  Down
};
export default iconMap;
